import { createTheme } from "@material-ui/core";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans'
        ].join(','),
        h1: {
            fontSize: 44,
            fontWeight: 600,
            lineHeight: 1.34,
            '@media (min-width: 900px)': {
                fontSize: 51,
                fontWeight: 600,
                lineHeight: 1.43,
            },
        },
        h2: {
            fontSize: 32,
            fontWeight: 600,
            lineHeight: 1.47,
            padding: '50px 0',
            '@media (min-width: 900px)': {
                fontSize: 44,
                fontWeight: 600,
                lineHeight: 1.34,
                padding: '100px 0',
            },
        },
        h3: {
            fontSize: 22,
            fontWeight: 600,
            lineHeight: 1.72,
            '@media (min-width: 900px)': {
                fontSize: 32,
                fontWeight: 600,
                lineHeight: 1.47,
            },
        },
        h4: {
            fontSize: 18,
            fontWeight: 600,
            lineHeight: 1.72,
        },
        h5: {
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 2.21,
        },
        body1: {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: 1.94,
            color: "#8690a0",
        },
    },
    palette: {
        common: {
            black: '#202338'
        },
        primary: {
            main: '#ea1674',
        },
        secondary: {
            main: '#f5f9f9',
        },
    },
    shape: {
        borderRadius: 0,
    },

    // theme overrides

    overrides: {
        MuiButton: {
            root: {
                color: '#ea1674',
                textTransform: 'none',
                fontSize: 16,
                fontWeight: 600,
            },
            contained: {
                color: '#ea1674',
                backgroundColor: 'white',
                boxShadow: 'none',
                border: 'solid 1px #ea1674',
                '&:hover': {
                    backgroundColor: 'white'
                }
            },
        }
    }
});

export default theme;